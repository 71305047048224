// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import AutomaticCheckoutCalculation2 from "../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SplitPayments from "../../blocks/SplitPayments/src/SplitPayments";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Documentation from "../../blocks/Documentation/src/Documentation";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import ShippingOptions from "../../blocks/ShippingOptions/src/ShippingOptions";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import CentralisedBilling from "../../blocks/CentralisedBilling/src/CentralisedBilling";
import ProposalGeneration2 from "../../blocks/ProposalGeneration2/src/ProposalGeneration2";
import CfDashboardForShippingCompanies from "../../blocks/CfDashboardForShippingCompanies/src/CfDashboardForShippingCompanies";
import CfPurchaseOrderGeneration from "../../blocks/CfPurchaseOrderGeneration/src/CfPurchaseOrderGeneration";
import SubscriptionBilling2 from "../../blocks/SubscriptionBilling2/src/SubscriptionBilling2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import OrderCreation from "../../blocks/OrderCreation/src/OrderCreation";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import KeywordSearch from "../../blocks/KeywordSearch/src/KeywordSearch";
import CfDashboardForInsurance from "../../blocks/CfDashboardForInsurance/src/CfDashboardForInsurance";
import Customform from "../../blocks/customform/src/Customform";
import Gallery from "../../blocks/Gallery/src/Gallery";
import StripeIntegration from "../../blocks/stripepayments/src/StripeIntegration";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import ServiceSpecificSettingsAdmin from "../../blocks/ServiceSpecificSettingsAdmin/src/ServiceSpecificSettingsAdmin";
import Wishlist2 from "../../blocks/Wishlist2/src/Wishlist2";
import Chatbot6 from "../../blocks/Chatbot6/src/Chatbot6";
import CfDashboardForBankReps from "../../blocks/CfDashboardForBankReps/src/CfDashboardForBankReps";
import Analytics from "../../blocks/analytics/src/Analytics";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import InventoryManagement2 from "../../blocks/InventoryManagement2/src/InventoryManagement2";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescription";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ShippingChargeCalculator from "../../blocks/ShippingChargeCalculator/src/ShippingChargeCalculator";
import Share from "../../blocks/share/src/Share";
import ShippingAddressValidation from "../../blocks/ShippingAddressValidation/src/ShippingAddressValidation";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import LanguageSupport from "../../blocks/LanguageSupport/src/LanguageSupport";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Sorting from "../../blocks/sorting/src/Sorting";
import MultipageForms2 from "../../blocks/MultipageForms2/src/MultipageForms2";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import LanguageOptions from "../../blocks/LanguageOptions/src/LanguageOptions";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import OrderManagementSync2 from "../../blocks/OrderManagementSync2/src/OrderManagementSync2";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import LocalinternationalShipping from "../../blocks/LocalinternationalShipping/src/LocalinternationalShipping";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import CfVendorPortal from "../../blocks/CfVendorPortal/src/CfVendorPortal";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
AutomaticCheckoutCalculation2:{
 component:AutomaticCheckoutCalculation2,
path:"/AutomaticCheckoutCalculation2"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SplitPayments:{
 component:SplitPayments,
path:"/SplitPayments"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
Documentation:{
 component:Documentation,
path:"/Documentation"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
ShippingOptions:{
 component:ShippingOptions,
path:"/ShippingOptions"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
CentralisedBilling:{
 component:CentralisedBilling,
path:"/CentralisedBilling"},
ProposalGeneration2:{
 component:ProposalGeneration2,
path:"/ProposalGeneration2"},
CfDashboardForShippingCompanies:{
 component:CfDashboardForShippingCompanies,
path:"/CfDashboardForShippingCompanies"},
CfPurchaseOrderGeneration:{
 component:CfPurchaseOrderGeneration,
path:"/CfPurchaseOrderGeneration"},
SubscriptionBilling2:{
 component:SubscriptionBilling2,
path:"/SubscriptionBilling2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
OrderCreation:{
 component:OrderCreation,
path:"/OrderCreation"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
KeywordSearch:{
 component:KeywordSearch,
path:"/KeywordSearch"},
CfDashboardForInsurance:{
 component:CfDashboardForInsurance,
path:"/CfDashboardForInsurance"},
Customform:{
 component:Customform,
path:"/Customform"},
Gallery:{
 component:Gallery,
path:"/Gallery"},
StripeIntegration:{
 component:StripeIntegration,
path:"/StripeIntegration"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
ServiceSpecificSettingsAdmin:{
 component:ServiceSpecificSettingsAdmin,
path:"/ServiceSpecificSettingsAdmin"},
Wishlist2:{
 component:Wishlist2,
path:"/Wishlist2"},
Chatbot6:{
 component:Chatbot6,
path:"/Chatbot6"},
CfDashboardForBankReps:{
 component:CfDashboardForBankReps,
path:"/CfDashboardForBankReps"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
InventoryManagement2:{
 component:InventoryManagement2,
path:"/InventoryManagement2"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
ProductDescription:{
 component:ProductDescription,
path:"/ProductDescription"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ShippingChargeCalculator:{
 component:ShippingChargeCalculator,
path:"/ShippingChargeCalculator"},
Share:{
 component:Share,
path:"/Share"},
ShippingAddressValidation:{
 component:ShippingAddressValidation,
path:"/ShippingAddressValidation"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
MultipageForms2:{
 component:MultipageForms2,
path:"/MultipageForms2"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
OrderManagementSync2:{
 component:OrderManagementSync2,
path:"/OrderManagementSync2"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
EmailNotifications2:{
 component:EmailNotifications2,
path:"/EmailNotifications2"},
LocalinternationalShipping:{
 component:LocalinternationalShipping,
path:"/LocalinternationalShipping"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
CustomisedOrderStatus:{
 component:CustomisedOrderStatus,
path:"/CustomisedOrderStatus"},
CfVendorPortal:{
 component:CfVendorPortal,
path:"/CfVendorPortal"},

  Home: {
component:ReviewApprovalAdmin,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
